import {Checkbox, FormControlLabel} from '@mui/material'
import {WidgetProps} from '@rjsf/core'

export function CheckboxWidget(props: WidgetProps) {
  const {value, onChange, required, label} = props
  return (
    <FormControlLabel
      sx={{typography: 'body2'}}
      control={
        <Checkbox
          size='small'
          required={required}
          checked={value}
          onChange={e => onChange(!value)}
        />
      }
      label={label}
    />
  )
}
