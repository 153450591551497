import {useEffect} from 'react'

declare global {
  interface Window {
    adsbygoogle: any
  }
}

export function GoogleAd(props: GoogleAdProps) {
  const {
    className,
    style,
    client,
    slot,
    layout,
    layoutKey,
    format,
    responsive,
  } = props

  useEffect(() => {
    let adsbygoogle = window.adsbygoogle || []
    adsbygoogle.push({})
  }, [])

  return (
    <ins
      className={`${className} adsbygoogle`}
      style={style}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-format={format}
      data-full-width-responsive={responsive}
    />
  )
}

export type GoogleAdProps = {
  className?: string
  style: object // eslint-disable-line
  client: string
  slot: string
  layout?: string
  layoutKey?: string
  format?: string
  responsive?: boolean
}
