import {AppBar, Toolbar, Button} from '@mui/material'
import {v4 as uuidv4} from 'uuid'

import {daxTemplateDataState} from '../../atoms'
import {useRecoilValue} from 'recoil'
import Axios from 'axios'

export function Footer() {
  const templateJson = useRecoilValue(daxTemplateDataState)

  const saveFile = () => {
    let guid = uuidv4()
    let filename = `${guid}.qdt.json`
    let contentType = 'application/json;charset=utf-8;'

    // TODO: We should add these to an environment file of some sort: buildVersion, uniqueId = guid, schemaVersion
    let payload = {
      ...templateJson,
      buildVersion: 'v1.0.0',
      uniqueId: guid,
      schemaVersion:
        'https://github.com/PowerBI-tips/DAX-Templates/blob/main/schema/v1.ts',
    }

    let a = document.createElement('a')
    a.download = filename
    a.href =
      'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(payload))
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    postFile(payload, guid)
  }
  const postFile = (json: object, guid: string) => {
    let data = {
      payload: JSON.stringify(json),
      guid: guid,
    }

    Axios({
      method: 'post',
      url: '/api/download-template',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
  }

  return (
    <AppBar
      position='fixed'
      color='primary'
      sx={{top: 'auto', bottom: 0, alignItems: 'center'}}
    >
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          display: 'flex',
          alignContent: 'center',
        }}
      >
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={() => saveFile()}
        >
          Download File
        </Button>
      </Toolbar>
    </AppBar>
  )
}
