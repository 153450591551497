import {DeleteOutline} from '@mui/icons-material'
import {IconButton, Button, Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Box} from '@mui/system'
import {ArrayFieldTemplateProps} from '@rjsf/core'
import {useState} from 'react'
import {themeColors} from '../../../../theme'

const useStyles = makeStyles((theme: Theme) => ({
  objectArrayElement: {
    background: themeColors.lightGray,
    border: `1px groove ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .arrayElementInput': {
      '& .field-object > fieldset': {
        border: 'none',
      },
    },
    '& .arrayElementButton': {
      alignSelf: 'flex-end',
      height: theme.spacing(5),
      width: theme.spacing(5),
      flexGrow: 0,
    },
  },

  addItem: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export function ObjectArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const {className, title, items, canAdd, onAddClick} = props
  const classes = useStyles()
  const [isHidden, setIsHidden] = useState(false)

  // if (items[0] && items[0].children.props.schema.type == 'string')
  return (
    <Box className={className} sx={{marginTop: 2}}>
      <Box
        sx={{marginBottom: 1, display: 'flex', justifyContent: 'space-between'}}
      >
        {title}
        <Button onClick={() => setIsHidden(!isHidden)}>
          {isHidden ? 'show' : 'hide'}
        </Button>
      </Box>
      <Box sx={{display: isHidden ? 'none' : 'block'}}>
        {items &&
          items.map((element, index) => (
            <div key={element.key}>
              <div className={`${classes.objectArrayElement}`}>
                <div className='arrayElementInput'>{element.children}</div>
                {element.hasRemove && (
                  <IconButton
                    className='arrayElementButton'
                    onClick={element.onDropIndexClick(element.index)}
                  >
                    <DeleteOutline />
                  </IconButton>
                )}
              </div>
            </div>
          ))}

        {canAdd && (
          <div className={classes.addItem}>
            <Button variant='outlined' size='small' onClick={onAddClick}>
              + {title}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  )
}
