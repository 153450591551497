import {atom} from 'recoil'
import { defaultFormData } from '../components/template-form/form-components'

export const daxTemplateDataState = atom({
  key: 'daxTemplateDataState', // unique ID (with respect to other atoms/selectors)
  default: defaultFormData, // default value (aka initial value)
})
export const daxExpressionState = atom({
  key: 'daxExpressionState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
})
