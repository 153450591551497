import {MenuItem, Select, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {WidgetProps} from '@rjsf/core'

type OneOfType = {
  value: string
  label: string
  schema: string
}
export function SelectWidget(props: WidgetProps) {
  const {id, value, options, label} = props
  const enumOptions = options.enumOptions as OneOfType[]
  return (
    <Box sx={{mt: 1}}>
      <Typography variant='subtitle2' id={id + '-label'}>
        {label}
      </Typography>
      <Select
        sx={{mb: 1}}
        labelId={id + '-label'}
        id={id}
        value={value}
        size='small'
        margin='dense'
      >
        {enumOptions &&
          enumOptions.map((option, index) => (
            <MenuItem key={id + index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </Box>
  )
}
