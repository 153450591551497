import {TextFieldWidget} from './text-field-widget'
import {TextAreaWidget} from './text-area-widget'
import {CheckboxWidget} from './checkbox-widget'
import {CheckboxesWidget} from './checkboxes-widget'
import {SelectWidget} from './select-widget'
import {RadioWidget} from './radio-widget'

export const widgets = {
  TextWidget: TextFieldWidget,
  TextareaWidget: TextAreaWidget,
  CheckboxWidget: CheckboxWidget,
  CheckboxesWidget: CheckboxesWidget,
  SelectWidget: SelectWidget,
  RadioWidget: RadioWidget,
}
