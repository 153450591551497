import {useRouteMatch} from 'react-router-dom'
import {
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'

export function PbiTipsToolsLinks() {
  return (
    // prettier-ignore
    <>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="Links-subheader"
          subheader={
            <ListSubheader component="div" id="Links-subheader">
                           <Typography sx={{
              color: 'theme.palette.primary.main', 
              fontWeight: 700,
              fontSize: '.75rem',
              textTransform:'uppercase'
            }}>Links</Typography>
            </ListSubheader>
          }
        >

          <NavigationLink path='https://powerbi.tips'>Website</NavigationLink>
          <NavigationLink path='https://www.youtube.com/powerbitips'>YouTube Channel</NavigationLink>
          <NavigationLink path='https://powerbi.tips/explicit-measures-power-bi-podcast/'>Podcast</NavigationLink>
          <NavigationLink path='https://swag.powerbi.tips'>Clothing</NavigationLink>
          <NavigationLink path='https://powerbi.tips/tools/scrims/'>Scrims</NavigationLink>
          <NavigationLink path='https://powerbi.tips/tools/layouts/'>Layouts</NavigationLink>
          <NavigationLink path='https://powerbi.tips/product-category/game/'>DAX Games</NavigationLink>

        </List>


        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="tools-subheader"
          subheader={
            <ListSubheader component="div" id="tools-subheader">
                           <Typography sx={{
              color: 'theme.palette.primary.main', 
              fontWeight: 700,
              fontSize: '.75rem',
              textTransform:'uppercase'
            }}>Tools</Typography>
            </ListSubheader>
          }
        >

          <NavigationLink path='https://themes.powerbi.tips'>Themes</NavigationLink>
          <NavigationLink path='https://gallery.powerbi.tips'>Themes Gallery</NavigationLink>
          <NavigationLink path='https://charts.powerbi.tips'>Charts</NavigationLink>
          <NavigationLink path='https://gallery.powerbi.tips/#/charts'>Charts Gallery</NavigationLink>
          <NavigationLink path='https://model.powerbi.tips'>Model</NavigationLink>
          <NavigationLink path='https://connections.powerbi.tips'>Connections</NavigationLink>

        </List>
        {/* TODO: Lets make this social media icons as a footer later */}
        <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="social-subheader"
        subheader={
          <ListSubheader component="div" id="social-subheader" >
             <Typography sx={{
              color: 'theme.palette.primary.main', 
              fontWeight: 700,
              fontSize: '.75rem',
              textTransform:'uppercase'
            }}>Social Media</Typography>
          </ListSubheader>
        }
      >

        <NavigationLink path='https://twitter.com/PowerBITips'>Twitter</NavigationLink>
        <NavigationLink path='https://www.facebook.com/PowerBI.Tips'>Facebook</NavigationLink>
        <NavigationLink path='https://www.linkedin.com/company/powerbitips/'>LinkedIn</NavigationLink>

      </List>
    </>
  )
}

function NavigationLink(props: NavLinkProps) {
  const {path, children} = props
  const isTabSelected = !!useRouteMatch(path)

  return (
    <ListItem button selected={isTabSelected} href={path} component={Link}>
      <ListItemText primary={children}></ListItemText>
    </ListItem>
  )
}

type NavLinkProps = {
  path: string
  children: string
}
