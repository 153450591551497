import {useState} from 'react'
import {Button, TextField} from '@mui/material'
import {Box} from '@mui/system'
import {useRecoilValue} from 'recoil'

import {daxExpressionState} from '../../atoms'
import {useWindowDimensions} from '../../hooks'

export function EvaluatedExpression() {
  const expression = useRecoilValue(daxExpressionState)
  const [isExpressionHidden, setIsExpressionHidden] = useState(false)
  const {height} = useWindowDimensions()

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        mb: 2,
      }}
    >
      <Box
        sx={{
          typography: 'subtitle2',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        Evaluated Expression
        <Button onClick={() => setIsExpressionHidden(!isExpressionHidden)}>
          {isExpressionHidden ? 'show' : 'hide'}
        </Button>
      </Box>
      <TextField
        sx={{
          display: isExpressionHidden ? 'none' : 'block',
        }}
        fullWidth
        variant='outlined'
        multiline
        maxRows={(height - 200) / 28}
        value={expression}
      />
    </Box>
  )
}
