import {Typography, Paper} from '@mui/material'
import {Box} from '@mui/system'
import {GoogleAd} from './google-ad'

// This component is a container of Ads that can be used in the application.
export function StackedAdContainer() {
  return (
    <Paper
      sx={{
        width: 'max-content',

        padding: {sm: 0, md: 1},
        height: '348px', // this is calcultated by adding the padding margin height of the ad, and the typography
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{mb: 1}}>
        <Typography variant='body1' align='center'>
          Ads
        </Typography>
      </Box>
      <GoogleAd
        client='ca-pub-1992076672624577'
        slot='4356116868'
        style={{display: 'inline-block', width: '300px', height: '300px'}}
      />
    </Paper>
  )
}
