import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {WidgetProps} from '@rjsf/core'

type AnyOfType = {
  value: string
  label: string
  schema: string
}

export function RadioWidget(props: WidgetProps) {
  const {label, options, value, onChange} = props
  const enumOptions = options.enumOptions as AnyOfType[]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant='subtitle2'>{label}</Typography>

      <RadioGroup row aria-label={`selection-${label}`}>
        {enumOptions &&
          enumOptions.map((option,index) => (
            <FormControlLabel
              key={`radio-${index}`}
              sx={{typography: 'body2'}}
              control={
                <Radio
                  size='small'
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => {
                    onChange(option.value)
                  }}
                />
              }
              label={option.label}
            />
          ))}
      </RadioGroup>
    </Box>
  )
}
