import {Checkbox, FormControlLabel, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {WidgetProps} from '@rjsf/core'

type AnyOfType = {
  value: string
  label: string
  schema: string
}

export function CheckboxesWidget(props: WidgetProps) {
  const {label, value, options, required, onChange} = props
  const enumOptions = options.enumOptions as AnyOfType[]
  
  const updateValue = (newValue: string) => {
    let foundIndex = value.indexOf(newValue)
    if (foundIndex === -1) {
      onChange([...value, newValue])
    } else {
      let updatedValues = [...value]
      updatedValues.splice(foundIndex, 1)
      onChange(updatedValues)
    }
  }
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant='subtitle2'>{label}</Typography>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        {enumOptions &&
          enumOptions.map((option, index) => (
            <FormControlLabel
              key={`checkbox-${index}`}
              sx={{typography: 'body2'}}
              control={
                <Checkbox
                  size='small'
                  required={required}
                  checked={value.indexOf(option.value) !== -1}
                  onChange={() => {
                    updateValue(option.value)
                  }}
                />
              }
              label={option.label}
            />
          ))}
      </Box>
    </Box>
  )
}
