import {createTheme} from '@mui/material'

export const themeColors = {
  darkText: '#2b2b2b',
  themeYellow: '#f1c80f',
  themeLightBlue: '#00aae1',
  themeDarkBlue: '#333f49',
  surface: '#efefef',
  lightGray: '#fafbfc',
  lightText: '#f5f5f5',
}

// TODO: move this to another file later
export const theme = createTheme({
  palette: {
    primary: {
      main: themeColors.themeDarkBlue,
    },
    secondary: {
      main: themeColors.themeYellow,
    },
    background: {
      default: '#efefef',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    button: {
      textTransform: 'capitalize',
    },
  },
})
