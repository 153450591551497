import {TextField} from '@mui/material'
import {WidgetProps} from '@rjsf/core'

export function TextFieldWidget(props: WidgetProps) {
  const {value, onChange, required, label, options} = props
  return (
    <TextField
      InputLabelProps={{shrink: true}}
      sx={{my: 1}}
      size='small'
      value={value}
      onChange={e => onChange(e.target.value)}
      required={required}
      fullWidth
      helperText={options.description}
      label={label}
    />
  )
}
