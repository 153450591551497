import {Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Box} from '@mui/system'
import {StackedAdContainer} from '../components'

import {EvaluatedExpression, TemplateForm} from '../components/template-form'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',

    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentCol: {
    [theme.breakpoints.up('sm')]: {
      flex: 6,
      margin: `0 ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '100%',
    },
  },
  secondContentCol: {
    [theme.breakpoints.up('sm')]: {
      flex: 4,
      margin: `0 ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '100%',
    },
  },
  fixedContainer: {
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
    },

    width: 'calc(40% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
export function MainPage() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.contentCol}>
        <TemplateForm />
      </div>
      <div className={classes.secondContentCol}>
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: 'calc(40% - 40px)',
          }}
        >
          <EvaluatedExpression />
          <StackedAdContainer />
        </Box>
      </div>
    </div>
  )
}
