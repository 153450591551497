import {makeStyles} from '@mui/styles'
import {Paper, Theme, Typography, Button} from '@mui/material'
import Form from '@rjsf/core'
import {useRecoilState, useSetRecoilState} from 'recoil'

import {
  schema,
  uiSchema,
  widgets,
  ArrayFieldTemplate,
  defaultFormData,
} from './form-components'
import {daxExpressionState, daxTemplateDataState} from '../../atoms'
import {Box} from '@mui/system'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& .control-label': {
      display: 'none',
    },
    '& .field-description': {
      display: 'none',
    },
    '& .form-group': {
      width: '100%',
    },
    '& .field-object': {
      border: 'none',
      // paddingLeft: theme.spacing(3),
      // paddingRight: theme.spacing(3),
    },
    '& .btn-info': {
      display: 'none',
    },
    '& #root': {
      border: 'none',
      margin: 0,
    },
  },
}))

export function TemplateForm() {
  const classes = useStyles()
  const setExpression = useSetRecoilState(daxExpressionState)
  const [data, setData] = useRecoilState(daxTemplateDataState)

  //@ts-ignore - Should come back and create a type for the formData here.
  const updateData = ({formData}, e) => {
    setData(formData)

    if (!formData.encoding) return
    //TODO :: This will be an array of Encoding objects, would need to list all formulas based on the number of Encodings.

    let parameterList = '// DAX Parameters\n'

    let expression = ''

    //@ts-ignore
    formData.parameters.forEach(parameter => {
      parameterList += `// ${
        parameter.displayName === undefined ? '-name-' : parameter.displayName
      } --> ${
        parameter.parameterKey === undefined ? '--' : parameter.parameterKey
      }\n`
    })

    //@ts-ignore
    formData.encoding.forEach((encoding, index) => {
      expression += `// Dax Expression ${index + 1}\n${
        encoding.name === undefined ? '--' : encoding.name
      }\n${encoding.expression === undefined ? '--' : encoding.expression}\n\n`
    })

    //@ts-ignore
    formData.parameters.forEach(parameter => {
      expression = expression.replaceAll(
        parameter.parameterKey,
        parameter.displayName
      )
    })

    setExpression(parameterList + '\n' + expression)
  }
  return (
    <Paper sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5' sx={{mt: 1, ml: 1}}>
          Create Template
        </Typography>
        <Button
          onClick={() => setData(defaultFormData)}
          variant='outlined'
          sx={{mt: 1, mr: 1}}
        >
          Clear All
        </Button>
      </Box>
      <Form
        className={classes.form}
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        ArrayFieldTemplate={ArrayFieldTemplate}
        formData={data}
        onChange={updateData}
        // liveValidate  // turns on validation for form
      />
    </Paper>
  )
}
