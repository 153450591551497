import * as React from 'react'
import {Divider, Drawer, Hidden, Theme} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'
import imageURL from '../../images/powerbitips-black.svg'
import {Link} from 'react-router-dom'

// const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      zIndex: 8,
      // [theme.breakpoints.up('sm')]: {
      //   width: drawerWidth,
      //   flexShrink: 0,
      // },
    },
    logoImage: {
      width: '200px',
    },
    // Necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      zIndex: 8,
      // width: drawerWidth,
    },
    drawerHeader: {
      margin: theme.spacing(2),
      mariginBottom: theme.spacing(1),
    },
  })
)

export function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const {children} = props
  const classes = useStyles()

  const drawerContents = (
    <div>
      <div className={classes.drawerHeader}>
        <Link to='https://powerbi.tips' className={classes.logoImage}>
          <img
            src={`${imageURL}`}
            className={classes.logoImage}
            alt='PowerBI.Tips'
          />
        </Link>
        {/* <Typography variant='h6'>PowerBI.tips Tools</Typography> */}
      </div>
      <Divider />
      {children}
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label='powerbi.tips tools'>
      <MobileDrawer {...props}>{drawerContents}</MobileDrawer>
      <DesktopDrawer {...props}>{drawerContents}</DesktopDrawer>
    </nav>
  )
}

export type ResponsiveDrawerProps = {
  children: React.ReactNode
  isOpen: boolean
  toggleOpen: () => void
}

function MobileDrawer(props: ResponsiveDrawerProps) {
  const {children, isOpen, toggleOpen} = props
  const classes = useStyles()

  return (
    // The implementation can be swapped with js to avoid SEO duplication of links.
    <Hidden smUp implementation='css'>
      <Drawer
        variant='temporary'
        anchor='left'
        open={isOpen}
        onClose={toggleOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {children}
      </Drawer>
    </Hidden>
  )
}

function DesktopDrawer(props: ResponsiveDrawerProps) {
  const {children, isOpen, toggleOpen} = props
  const classes = useStyles()

  return (
    <Hidden xsDown implementation='css'>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant='temporary'
        open={isOpen}
        onClose={toggleOpen}
      >
        {children}
      </Drawer>
    </Hidden>
  )
}
